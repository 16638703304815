import {WpArgPages} from "./page.types";
import {Page} from "./page.types";
import Config from "../constants";
import {Api} from "./api";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
const queryString = require('query-string');

class PageService extends Api {
  public constructor (config: AxiosRequestConfig) {
    super(config);
  }

  /**
   * @param {object} args - .
   * @returns {Promise<User[]>} users collection,
   */
  public loadPages (args: WpArgPages): Promise<Page[]> {
    return this.get<Page[]>(`/pages?${queryString.stringify(args)}&_embed`)
      .then((response: AxiosResponse<Page[]>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}
const baseURL = `${Config.apiUrl}${Config.apiNamespaceV2}`;
export const pageService = new PageService(Object.assign({baseURL: baseURL}, Config.axiosConfig));
