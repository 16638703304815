import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  fetchPages,
  selectPages,
} from './pageSlice';
import {Page} from "../../services/page.types";
import './page-page.scss';

type Props = {
  match: {
    params: {
      slug: string;
    };
  };
  slug: string;
};

const PagePage: React.FC<Props> = (props) => {
  const baseArgs = {offset: 0};
  const [page, setPage] = useState<Page | undefined>(undefined);
  const dispatch = useDispatch();
  const pageJson = useSelector(selectPages);
  useEffect(() => {
    dispatch(fetchPages(Object.assign({}, baseArgs, {slug: props.match.params.slug}))
    );
  }, [props, dispatch, baseArgs]);
  useEffect(() => {
    if (pageJson.length > 0) setPage(pageJson[0]);
  }, [pageJson]);

  const htmlContent = () => {
    if (page) return {__html: page.content.rendered};
    return {__html: ''};
  };

  if (!page) return <div>Loading...</div>;
  return (
    <div id="pagePage" className="container">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{page.title.rendered}</h5>
          <div className="card-text" dangerouslySetInnerHTML={htmlContent()} />
        </div>
      </div>
    </div>
  );
};

export default PagePage;
