import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  fetchLinks,
  selectLinks,
} from './linksSlice';
import {RefLink} from '../../services/link.types';
import {htmlContent} from '../../shared/renderHelpers';
import Rating from '../../components/rating/Rating';
import './links-page.scss';

type Props = {
};

type Ctg = {
  name: string;
  active: boolean;
};
const LinksPage: React.FC<Props> = (props) => {
  const [items, setItems] = useState<RefLink[] | undefined>(undefined);
  const linksJson = useSelector(selectLinks);
  const dispatch = useDispatch();
  const [uniqueCats, setUniqueCats] = useState<Ctg[] | undefined>(undefined);
  useEffect(() => {
    dispatch(fetchLinks({}));
  }, [props, dispatch]);
  useEffect(() => {
    if (linksJson.length) {
      setItems(linksJson);
    }
  }, [linksJson]);
  useEffect(() => {
    if (items && items.length) {
      const ctgs: string[] = [];
      const cats = items.reduce((acc: Ctg[], curr: RefLink) => {
        curr.categories.forEach(cat => {
          if (!ctgs.includes(cat)) {
            acc.push({name: cat, active: true});
            ctgs.push(cat);
          }
        });
        return acc;
      }, []);
      setUniqueCats(cats);
    }
  }, [items]);
  const toggleSelect = (cat: Ctg) => {
    setUniqueCats(uniqueCats?.map(item => {
      if (item.name === cat.name) item.active = !item.active;
      return item;
    }).sort((a, b) => {
      return a.active === true ? -1 : 0;
    }));
  };
  const clearAll = () => {
    setUniqueCats(uniqueCats?.map(item => {
      item.active = false;
      return item;
    }));
  };
  const activeAll = () => {
    setUniqueCats(uniqueCats?.map(item => {
      item.active = true;
      return item;
    }));
  };
  return (
    <section id="linksPage" className="container page-container">
      <h1 className="page-title">Links</h1>

      <div id="categories">
        {uniqueCats?.map(item => {
          return <><span className={`btn btn-sm ${item.active ? 'btn-primary' : 'btn-light'}`} onClick={() => toggleSelect(item)} key={item.name}>{item.name}</span></>;
        })}
        <span className={`btn btn-sm btn-warning`} onClick={() => clearAll()}>Clear All</span>
        <span className={`btn btn-sm btn-info`} onClick={() => activeAll()}>Show All</span>
      </div>

      <section className="links-container">
        {items?.map(item => item)
          .filter(item => {
            const filter = item.categories.reduce((acc, curr) => {
              // console.log(':test > ', uniqueCats?.filter(item => item.active).findIndex(item => item.name === curr));
              const idx = uniqueCats?.filter(item => item.active).findIndex(item => item.name === curr);
              console.log(':idx > ', idx);
              if (acc && idx && idx === -1) acc = false;
              return acc;
            }, true);
            console.log(':idx > ', filter);
            return filter;
          })
          .map(item => {
            // console.log(':item > ', item);
            return (
              <a href={item.url} key={item.id} className="link-item nav-link" target="_blank" rel="noopener noreferrer">
                <div className="item-name" dangerouslySetInnerHTML={htmlContent(item.name)}></div>
                <div className="item-description" dangerouslySetInnerHTML={htmlContent(item.description)}></div>
                <div className="item-meta">
                  {item.rating && item.rating !== '0' &&
                    <em className="cat cat-light">
                      <Rating rate={item.rating} />
                    </em>
                  }

                  {item.categories.map(cat => {
                    return (
                      <em className="cat cat-light" key={cat}>{cat}</em>
                    );
                  })}
                </div>
              </a>
            );
          })}
      </section>
    </section>
  );
};
export default LinksPage;
