import React from 'react';
import {Link} from 'react-router-dom';
import {PostEmbedded} from '../../services/post.types';
import TagLink from '../tag-link/TagLink';
import {getFeaturedBySize} from '../../shared/mediaSizes';
import {htmlContent} from '../../shared/renderHelpers';
import './gallery-item.scss';

type Props = {
  item: PostEmbedded;
};

const GalleryItem: React.SFC<Props> = (props) => {
  const {embeded_featured_image} = props.item;
  const imgData = getFeaturedBySize(embeded_featured_image.media_details.sizes, 'large');
  return (
    <div className="gallery-item col-sm-4">
      <div className="card">
        {imgData &&
          <Link to={{pathname: `/entry/${props.item.slug}`}}>
            <img className="card-img-top" src={imgData.source_url} alt={props.item.embeded_featured_image.alt_text} />
          </Link>
        }

        <div className="card-body">
          <Link to={{pathname: `/entry/${props.item.slug}`}} >
            <h6 className="card-title text-truncate" dangerouslySetInnerHTML={htmlContent(props.item.title.rendered)}></h6>
          </Link>

          <section className="tags">
            {props.item._embedded['wp:term']
              .flatMap(term => term)
              .map(item => {
                return <TagLink tag={item} key={item.id} />;
              })}
          </section>
        </div>
      </div>
    </div>
  );
};

export default GalleryItem;
