import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {
  fetchMenu,
  selectMenu,
} from './menuSlice';
import './top-navbar.scss';

const TopNavbar: React.FC = () => {
  // const [scrollRef, setScrollRef] = useState(React.createRef());
  const [collapse, setCollapse] = useState(true);
  const dispatch = useDispatch();
  const menu = useSelector(selectMenu);
  useEffect(() => {
    dispatch(fetchMenu('header-menu'));
  }, [dispatch]);
  useEffect(() => {
    const onResize = () => {
      const doc = window.document.documentElement;
      setCollapse(doc.clientWidth < 768);
    };
    window.addEventListener('resize', onResize, true);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);


  function toggleNav() {
    setCollapse(!collapse);
  }

  const menuItems = menu.map((item: any) => {
    if (item.object === 'custom') {
      return (
        <li className="nav-item" key={item.ID}>
          <Link to={item.url} key={item.ID} className="nav-link">{item.title}</Link>
        </li>
      );
    }

    const slug = (function getSlug(url) {
      const parts = url.split('/');
      return parts.length > 2 ? parts[parts.length - 2] : '';
    }(item.url));

    const route = `/${item.object}/${slug}`;

    return (
      <li className="nav-item" key={item.ID}>
        <Link to={route} className="nav-link"> {item.title} </Link>
      </li>
    );
  });

  const burgerClasses = classNames({
    'navbar-toggler': true,
    'is-active': collapse
  });

  const navbarClasses = classNames({
    'navbar-collapse': true,
    collapse: collapse,
  });

  return (
    <nav className="navbar navbar-expand-md fixed-top" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <Link to="/home">AUXCODER</Link>
        </div>

        <div className={burgerClasses} role="button" tabIndex={0} onClick={toggleNav} onKeyPress={toggleNav}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" focusable="false"><title>Menu</title><path stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M4 7h22M4 15h22M4 23h22" /></svg>
        </div>

        <div className={navbarClasses} id="navbarNav">
          <div className="navbar-nav">
            {menuItems}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopNavbar;
