import {createSlice} from '@reduxjs/toolkit';
import {feedbackService} from '../../services/feedback.service';
import {AppDispatch} from "../../store/store";
import {FeedbackParams} from '../../services/feedback.types';

export const contactSlice = createSlice({
  name: 'feedback',
  initialState: {
    running: 'idle',
    item: {},
    error: ''
  },
  reducers: {
    postFeedbackRequest: state => {
      if (state.running === 'idle') {
        state.running = 'pending';
      }
    },
    postFeedbackSuccess: (state, action) => {
      if (state.running === 'pending') {
        state.running = 'idle';
        state.item = action.payload;
      }
    },
    postFeedbackError: (state, action) => {
      if (state.running === 'pending') {
        state.running = 'idle';
        state.error = action.payload.response.data.message;
      }
    },
    resetFeedback: state => {
      if (state.running === 'pending') {
        state.running = 'idle';
        state.item = {};
        state.error = '';
      }
    }
  },
});

export const {postFeedbackRequest, postFeedbackSuccess, postFeedbackError, resetFeedback} = contactSlice.actions;

export const postFeedback = (body: FeedbackParams) => async (dispatch: AppDispatch) => {
  dispatch(postFeedbackRequest());
  try {
    const response: any = await feedbackService.postFedback(body);
    dispatch(postFeedbackSuccess(response));
  } catch (error) {
    dispatch(postFeedbackError(error));
  }
};

export const selectFeedback = (state: any) => state.feedback.item;
export const selectRunning = (state: any) => state.feedback.running;
export const selectError = (state: any) => state.feedback.error;

export default contactSlice.reducer;
