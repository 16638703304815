import {Post, PostEmbedded, WpArgPosts } from "./post.types";
import Config from "../constants";
import {Api} from "./api";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
const queryString = require('query-string');

class PostService extends Api {
  public constructor (config: AxiosRequestConfig) {
    super(config);
  }

  public loadPosts(args?: WpArgPosts): Promise<PostEmbedded[]|[]> {
    return this.get<PostEmbedded[]>(`/posts?${queryString.stringify(args, {arrayFormat: 'bracket'})}&_embed`)
      .then((response: AxiosResponse<PostEmbedded[]>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public loadPost(slug: string): Promise<Post|PostEmbedded> {
    return this.get<Post|PostEmbedded>(`/posts?slug=${slug}&_embed`)
      .then((response: AxiosResponse<Post|PostEmbedded>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}
const baseURL = `${Config.apiUrl}${Config.apiNamespaceV2}`;
export const postService = new PostService(Object.assign({baseURL: baseURL}, Config.axiosConfig));
