import React from 'react';
import Masonry from 'react-masonry-component';
import GalleryItem from '../gallery-item/GalleryItem';
import {PostEmbedded} from '../../services/post.types';
import './gallery-grid.scss';

type Props = {
  items: PostEmbedded[];
};

const GalleryGrid: React.FC<Props> = (props) => {
  function updateItem() {
    return props.items.filter(item => item.embeded_featured_image)
      .map(item => <GalleryItem item={item} key={item.id} />);
  }
  const childElements = updateItem();
  const masonryOptions = {
    transitionDuration: '0.7s',
    percentPosition: true,
  };

  return (
    <div className="gallery-grid">
      <Masonry
        className="row"
        options={masonryOptions}
        updateOnEachImageLoad={false}
      >
        {childElements}
      </Masonry>
    </div>
  );
};
export default GalleryGrid;
