import {createSlice} from '@reduxjs/toolkit';
import {pageService} from '../../services/page.service';
import {Page, WpArgPages} from '../../services/page.types';
import {AppDispatch} from "../../store/store";

export const pageSlice = createSlice({
  name: 'pages',
  initialState: {
    loading: 'idle',
    items: [],
    error: ''
  },
  reducers: {
    fetchPagesRequest: state => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    fetchPagesSuccess: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.items = action.payload;
      }
    },
    fetchPagesError: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.error = action.payload;
      }
    }
  },
});

export const {fetchPagesRequest, fetchPagesSuccess, fetchPagesError} = pageSlice.actions;

export const fetchPages = (args: WpArgPages) => async (dispatch: AppDispatch) => {
  dispatch(fetchPagesRequest());
  try {
    const response: Page[] = await pageService.loadPages(args);
    dispatch(fetchPagesSuccess(response));
  } catch (error) {
    dispatch(fetchPagesError(error));
  }
};

export const selectPages = (state: {pages: {items: Page[]|[]}}) => state.pages.items;

export default pageSlice.reducer;
