type ImgData = {
  loading: boolean;
  file?: string;
  source_url?: string;
  width: string;
  height: string;
}
// traverse available size for a match, return a default size (smallest)
function searchBysize(sizes: any, target: string) {
  const sizesList = ['full', 'large', 'medium', 'thumbnail'];
  return Object.keys(sizes).reduce((acc, curr) => {
    let found = false;
    if (sizesList.indexOf(curr) > -1 && !found) acc = curr; found = true;
    return acc;
  }, 'thumbnail');
}

export  const getFeaturedBySize = (sizes: any, target: string): ImgData => {
  const mediaSize = searchBysize(sizes, target)
  return mediaSize ? Object.assign({}, {loading: true}, sizes[mediaSize]) : null;
};

export const getEmbededBySize = (sizes: any, target: string): ImgData => {
  const mediaSize = searchBysize(sizes, target)
  return mediaSize ? Object.assign({}, {loading: true}, sizes[mediaSize]) : null;
};

// todo: switch image size by view size
