import React from 'react';
import ImageItem from '../image-item/ImageItem';
import './attached-item.scss';

type Props = {
  item: any;
};

const AttachedItem: React.FC<Props> = (props) => {
  const size = {
    width: props.item.width | 0,
    height: props.item.height | 0,
  };
  return (
    <section className={`single-image-item section text-center`}>
      <figure className="image" style={size}>
        <ImageItem url={props.item.url} size={size} alt={props.item.alt} />
        <figcaption>{props.item.caption}</figcaption>
      </figure>
    </section >
  );
}

export default AttachedItem;
