import {createSlice} from '@reduxjs/toolkit';
import {linkService} from '../../services/link.service';
import {AppDispatch} from "../../store/store";
import {RefLink, WpLinksArgs} from "../../services/link.types";

export const linksSlice = createSlice({
  name: 'links',
  initialState: {
    loading: 'idle',
    items: [],
    error: ''
  },
  reducers: {
    fetchLinksRequest: state => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    fetchLinksSuccess: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.items = action.payload;
      }
    },
    fetchLinksError: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.error = action.payload;
      }
    }
  },
});

export const {fetchLinksRequest, fetchLinksSuccess, fetchLinksError} = linksSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const fetchLinks = (args: WpLinksArgs) => async (dispatch: AppDispatch) => {
  dispatch(fetchLinksRequest());
  try {
    const response: RefLink[] = await linkService.loadLinks(args);
    dispatch(fetchLinksSuccess(response));
  } catch (error) {
    dispatch(fetchLinksError(error));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectLinks = (state: {links: {items: RefLink[]|[]}}) => state.links.items;
export const selectLoading = (state: {links: {loading: string}}) => state.links.loading;

export default linksSlice.reducer;
