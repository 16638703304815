import {Feedback} from "./feedback.types";
import Config from "../constants";
import {Api} from "./api";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {FeedbackParams} from './feedback.types';

class FeedbackService extends Api {
  public constructor (config: AxiosRequestConfig) {
    super(config);
    this.postFedback = this.postFedback.bind(this);
  }

  public postFedback(args: FeedbackParams): Promise<Feedback> {
    return this.post<Feedback>('/email', JSON.stringify(args))
      .then((response: AxiosResponse<Feedback>) => {
        const {data} = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}
const baseURL = `${Config.apiUrl}${Config.apiNamespaceHeadless}`;
export const feedbackService = new FeedbackService(Object.assign({baseURL: baseURL}, Config.axiosConfig));
