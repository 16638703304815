import React from 'react';

type Props = {
  rate: number | string;
};
const Rating: React.FC<Props> = ({rate}) => {
  const rateAsArray = Array.from(Array(Number(rate)), () => {
    return '*';
  });
  return (
    <>{rateAsArray}</>
  );
};
export default Rating;
