import React, {useEffect, useState} from 'react';
import './image-item.scss';

type Props = {
  url: string;
  size: {
    width: number;
    height: number;
  };
  alt: string;
};

const ImageItem: React.FC<Props> = (props) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, [loaded]);
  function handleImageLoaded(props: Props) {
    setLoaded(true);
  }

  return (
    <>
      {!loaded &&
        <div className="spinner-conrainer">
          <div className="spinner-grow text-secondary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }

      <img onLoad={() => handleImageLoaded} src={props.url} alt={props.alt} className="img-fluid" />
    </>
  );
}

export default ImageItem;
