import {createSlice} from '@reduxjs/toolkit';
import {menuService} from '../../services/menu.service';
import {MenuPayload} from "../../services/menu.type";
import {AppDispatch} from "../../store/store";

export const menuSlice = createSlice({
  name: 'mainmenu',
  initialState: {
    loading: 'idle',
    items: [],
    error: '',
  },
  reducers: {
    fetchMenuRequest: state => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    fetchMenuSuccess: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.items = action.payload.items;
      }
    },
    fetchMenuError: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.error = action.payload;
      }
    }
  },
});

export const {fetchMenuRequest, fetchMenuSuccess, fetchMenuError} = menuSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const fetchMenu = (slug: string) => async (dispatch: AppDispatch) => {
  dispatch(fetchMenuRequest());
  const response: MenuPayload = await menuService.loadMenu(slug)
  dispatch(fetchMenuSuccess(response));
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectMenu = (state: {menu: {items: any[]}}) => {
  return state.menu.items;
}

export default menuSlice.reducer;
