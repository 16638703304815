import {createSlice} from '@reduxjs/toolkit';
import {postService} from '../../services/post.service';
import {PostEmbedded, WpArgPosts} from '../../services/post.types';
import {AppDispatch} from "../../store/store";

export const postsSlice = createSlice({
  name: 'posts',
  initialState: {
    loading: 'idle',
    items: [],
    error: ''
  },
  reducers: {
    fetchPostRequest: state => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    },
    fetchPostSuccess: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.items = action.payload;
      }
    },
    fetchPostError: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.error = action.payload;
      }
    }
  },
});

export const {fetchPostRequest, fetchPostSuccess, fetchPostError} = postsSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const fetchPosts = (args: WpArgPosts) => async (dispatch: AppDispatch) => {
  dispatch(fetchPostRequest());
  try {
    const response: PostEmbedded[] = await postService.loadPosts(args);
    dispatch(fetchPostSuccess(response));
  } catch (error) {
    dispatch(fetchPostError(error));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectPosts = (state: {posts: {items: PostEmbedded[]|[]}}) => state.posts.items;
export const selectLoading = (state: {posts: {loading: string}}) => state.posts.loading;

export default postsSlice.reducer;
