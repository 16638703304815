export const FieldTypes = {
  text: {
    re: /[a-z0-9\s.+-]/i,
    msg: 'Invalid Character(s)',
  },
  email: {
    re: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    msg: 'This email is invalid',
  },
  phone: {
    re: /^[(]?[0-9]{3}[)]?[- .]?[0-9]{3}[- .]?[0-9]{4,6}$/,
    msg: 'This phone is invalid'
  },
  message: {
    re: /\W/gmi,
    msg: 'This message contains invalid characters'
  }
};
