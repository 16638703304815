import React from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import TopNavbar from './components/top-navbar/TopNavbar';
import store, {history} from './store/store';
import ContactPage from './pages/contact/ContactPage';
import HomePage from './pages/home/HomePage';
import EntryPage from './pages/entry/EntryPage';
import PagePage from './pages/page/PagePage';
import LinksPage from './pages/links/LinksPage';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <TopNavbar />

        <Switch>
          <Route path="/contact" component={ContactPage} />
          <Route path="/home" component={HomePage} />
          <Route path="/page/:slug" component={PagePage} />
          <Route path="/entry/:slug" component={EntryPage} />
          <Route path="/links" component={LinksPage} />
          <Route path="/" component={HomePage} />
          {/* <Route path="*" component={NotFound} /> */}
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
