import {RefLink, WpLinksArgs} from './link.types';
import Config from '../constants';
import {Api} from './api';
import {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
const queryString = require('query-string');

class LinkService extends Api {
  public constructor (config: AxiosRequestConfig) {
    super(config);
  }

  /**
   * @param {object} args - .
   * @returns {Promise<User[]>} users collection,
   */
  public loadLinks (args: WpLinksArgs): Promise<RefLink[]> {
    return this.get<RefLink[]>(`/links?${queryString.stringify(args)}`)
      .then((response: AxiosResponse<RefLink[]>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}
const baseURL = `${Config.apiUrl}${Config.apiNamespaceV2}`;
export const linkService = new LinkService(Object.assign({baseURL: baseURL}, Config.axiosConfig));
