const common = {
  apiRoot: '/wp-json',
  apiNamespaceHeadless: '/wp-json/headless/v1',
  apiNamespaceV1: '/wp-json/wp/v1',
  apiNamespaceV2: '/wp-json/wp/v2',
  apiPageSegment: '/page',
  apiPostSegment: '/post',
  googlePlacesAPIKey: 'AIzaSyBok97FQqj-n5BE0JMgYiU6kFl4neTi8RA',
  placeId: 'ChIJE3bCu_6ipBIRvDSZu0vVswM',
  mapboxglAccessToken: 'pk.eyJ1IjoiYXV4Y29kZXIiLCJhIjoiY2poZG03bWFpMG9zOTMwcGl4MHN3cm0ycCJ9.qxXLJP9HIrcZKQe1WOpKuQ',
  axiosConfig: {
    returnRejectedPromiseOnError: true,
    // withCredentials: true,
    timeout: 30000,
    headers: {
      // "Cache-Control": "no-cache, no-store, must-revalidate",
      // Pragma: "no-cache",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    // paramsSerializer: (params: PathLike) => qs.stringify(params, { indices: false }),
  }
};

const dev = {
  apiUrl: 'https://api-auxcoder.auxcoder.com',
  homeUrl: 'http://localhost:3000',
};

const prod = {
  apiUrl: 'https://api-auxcoder.auxcoder.com',
  homeUrl: 'https://auxcoder.com',
};

const envs = process.env.NODE_ENV === 'development' ? Object.assign(common, dev) : Object.assign(common, prod);

export default envs;
