import {MenuPayload} from "./menu.type";
import Config from "../constants";
import {Api} from "./api";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";

class MenuService extends Api {
  public constructor (config: AxiosRequestConfig) {
    super(config);
  }

  /**
   * @param {object} args - .
   * @returns {Promise<User[]>} users collection,
   */
  public loadMenu (slug: string): Promise<MenuPayload> {
    return this.get<MenuPayload>(`/menus/v1/menus/${slug}`)
      .then((response: AxiosResponse<MenuPayload>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}
const baseURL = `${Config.apiUrl}${Config.apiRoot}`;
export const menuService = new MenuService(Object.assign({baseURL: baseURL}, Config.axiosConfig));
