import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Lightbox from 'react-image-lightbox';
import {
  fetchEntry,
  selectEntry,
} from './entrySlice';
import AttachedItem from '../../components/attached-item/AttachedItem';
import TagLink from '../../components/tag-link/TagLink';
import {Post} from '../../services/post.types';
import {getEmbededBySize} from '../../shared/mediaSizes';
import {htmlContent} from '../../shared/renderHelpers';
import 'react-image-lightbox/style.css';
import './entry-page.scss';

type Props = {
  match: {
    params: {
      slug: string;
    };
  };
  slug: string;
};

const EntryPage: React.FC<Props> = (props) => {
  const [media, setMedia] = useState<JSX.Element[] | undefined>(undefined);
  const [tags, setTags] = useState<JSX.Element[] | undefined>(undefined);
  const [entry, setEntry] = useState<Post | undefined>(undefined);
  const [images, setImages] = useState<any>();
  const [lgbox, setLgbox] = useState({
    photoIndex: 0,
    isOpen: false,
  });
  const dispatch = useDispatch();
  const post = useSelector(selectEntry);
  useEffect(() => {dispatch(fetchEntry(props.match.params.slug));}, [dispatch, props]);
  useEffect(() => {
    if (post.length > 0) {
      const {attached_media} = post[0];
      setEntry(post[0]);

      const attachedMedia = Object.values(attached_media)
        .map(item => {
          const imageData = getEmbededBySize(item.post_metadata.sizes, 'large');
          const uploadUrl = (item: any) => item.guid.match(/([a-z:/.-]*[\d/]+)/i)[1];
          const imgObj = {
            id: item.ID,
            url: `${uploadUrl(item)}${imageData.file}`,
            caption: item.post_metadata.caption,
            alt: item.post_title,
          };
          return Object.assign({}, imageData, imgObj);
        });
      setImages(attachedMedia);

      const media = attachedMedia.map((item, idx) => {
        return <a href="/#" className="open-lbx" onClick={() => setLgbox({photoIndex: idx, isOpen: true})} key={item.id}><AttachedItem item={item} key={item.id} /></a>;
      });
      setMedia(media);

      const _tags = post[0]._embedded['wp:term']
        .flatMap(term => term)
        .map((item) => {
          return <TagLink tag={item} key={item.id} />;
        });
      setTags(_tags);
    }
  }, [post]);

  const handleOnOpenLb = (idx: number = 0) => {
    setLgbox(Object.assign({}, lgbox, {photoIndex: idx, isOpen: true}));
  };

  if (!entry) {
    return (
      <section id="entryPage" className="container">
        <div>Loading...</div>
      </section>
    );
  }

  return (
    <section id="entryPage" className="container page-container">
      <h1 className="page-title" dangerouslySetInnerHTML={htmlContent(entry.title.rendered)}></h1>

      <section className="tags">{tags}</section>

      <div className="columns">
        <section className="column">
          <div className="entry-content" dangerouslySetInnerHTML={htmlContent(entry.content.rendered)} />
        </section>
      </div>

      <button className="btn btn-primary btn-sm mb-3" onClick={() => handleOnOpenLb(0)}>
        Play Lightbox
        <svg className="bi bi-play-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 010 1.393z" /> </svg>
      </button>

      {lgbox.isOpen &&
        <Lightbox
          mainSrc={images[lgbox.photoIndex].url}
          nextSrc={images[(lgbox.photoIndex + 1) % images.length].url}
          prevSrc={images[(lgbox.photoIndex + images.length - 1) % images.length].url}
          onCloseRequest={() =>
            setLgbox(Object.assign({}, lgbox, {isOpen: false}))
          }
          onMovePrevRequest={() =>
            setLgbox(Object.assign({}, lgbox, {
              photoIndex: (lgbox.photoIndex + images.length - 1) % images.length
            }))
          }
          onMoveNextRequest={() =>
            setLgbox(Object.assign({}, lgbox, {
              photoIndex: (lgbox.photoIndex + 1) % images.length
            }))
          }
          reactModalStyle={{overlay: {zIndex: 1050, backgroundColor: 'rgba(96, 125, 139, 0.5)'}}}
          imagePadding={20}
        />
      }

      <section> {media} </section>
    </section>
  );
};

export default EntryPage;
