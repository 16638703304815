// reducers.js
import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {History} from "history";
import menuReducer from '../components/top-navbar/menuSlice';
import postsReducer from '../pages/home/postsSlice';
import entryReducer from '../pages/entry/entrySlice';
import pageReducer from '../pages/page/pageSlice';
import feedbackReducer from '../pages/contact/contactSlice';
import linksReducer from '../pages/links/linksSlice';

const createRootReducer = (history: History) => combineReducers({
  menu: menuReducer,
  posts: postsReducer,
  entry: entryReducer,
  pages: pageReducer,
  feedback: feedbackReducer,
  links: linksReducer,
  router: connectRouter(history),
})

export default createRootReducer
export type RootState = ReturnType<typeof createRootReducer>
