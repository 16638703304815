import {createSlice} from '@reduxjs/toolkit';
import {postService} from '../../services/post.service';
import {Post, PostEmbedded} from '../../services/post.types';
import {AppDispatch} from "../../store/store";

export const entrySlice = createSlice({
  name: 'entry',
  initialState: {
    loading: 'idle',
    items: [],
    error: ''
  },
  reducers: {
    fetchEntryRequest: state => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    fetchEntrySuccess: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.items = action.payload;
      }
    },
    fetchEntryError: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = action.payload.response.data.message;
      }
    },
  },
});

export const {fetchEntryRequest, fetchEntrySuccess, fetchEntryError} = entrySlice.actions;

export const fetchEntry = (slug: string) => async (dispatch: AppDispatch) => {
  dispatch(fetchEntryRequest());
  try {
    const response: Post = await postService.loadPost(slug);
    dispatch(fetchEntrySuccess(response));
  } catch (error) {
    dispatch(fetchEntryError(error));
  }
};

export const selectEntry = (state: {entry: {items: PostEmbedded[]|[]}}) => state.entry.items;

export default entrySlice.reducer;
