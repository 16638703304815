import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import {createBrowserHistory} from 'history'
import createRootReducer from './root-reducer';
import logger from 'redux-logger';

export const history = createBrowserHistory();
const middleware = [...getDefaultMiddleware(), logger]

const store = configureStore({
  reducer: createRootReducer(history),
  devTools: true,
  middleware
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./root-reducer', () => {
    const newRootReducer = require('./root-reducer').default
    store.replaceReducer(newRootReducer)
  })
}

export default store;
export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch = () => useDispatch<AppDispatch>()
// Export a hook that can be resused to resolve types
