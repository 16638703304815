import React from 'react';
import {Link} from 'react-router-dom';
import {EmbeddedWpTerm} from "../../services/post.types";
import './tag-link.scss';

type Prop = {
  tag: EmbeddedWpTerm;
};

const TagLink: React.SFC<Prop> = (props) => {
  const argType = props.tag.taxonomy === 'category' ? 'category' : 'tag';
  return (
    <Link
      to={{
        pathname: '/home',
        search: `?${argType}=${props.tag.id}&name=${props.tag.name}`
      }}
      key={props.tag.id}
      className="badge badge-light"
    >
      {props.tag.name}
    </Link>
  );
};

export default TagLink;
