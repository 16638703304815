import './home-page.scss';
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  fetchPosts,
  selectPosts,
  selectLoading,
} from './postsSlice';
import GalleryGrid from '../../components/gallery-grid/GalleryGrid';
import {WpArgPosts} from '../../services/post.types';
const queryString = require('query-string');

type HomeProps = {
  location: {
    search: string;
  };
};

const HomePage: React.FC<HomeProps> = (props) => {
  const baseArgs: WpArgPosts = {per_page: 9, offset: 0, tags: [], categories: []};
  const [title, setTitle] = useState('');
  const [args, setArgs] = useState(baseArgs);
  const dispatch = useDispatch();
  const posts = useSelector(selectPosts);
  const loading = useSelector(selectLoading);
  useEffect(() => {
    const search: {[k: string]: any;} = queryString.parse(props.location.search);
    setTitle(search.name);
    const newArgs = Object.assign({}, baseArgs);
    if ('tag' in search) newArgs.tags.push(search.tag);
    if ('category' in search) newArgs.categories.push(search.category);
    setArgs(newArgs);
    dispatch(fetchPosts(newArgs));
  }, [dispatch, props.location.search]);
  const handlePag = (val: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    // const offset = args.offset ? args.offset : 0;
    const perPage = args?.per_page ? args.per_page : 0;
    const newOffset = val === 'next' ? args.offset + perPage : args.offset - perPage;
    const newArgs = Object.assign(args, {offset: newOffset});
    setArgs(newArgs);
    dispatch(fetchPosts(newArgs));
  };

  if (!posts) {
    return <div id="homePage" className="container"><p>Loading view...</p></div>;
  }

  return (
    <div id="homePage" className="container page-container">
      <div className="d-flex justify-content-between mb-3">
        <button type="button" className="btn btn-primary d-flex align-items-center btn-sm" aria-expanded="false" dir-data="prev" onClick={handlePag('prev')}>
          <svg className="bi bi-arrow-left-short" width="28" height="28" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M7.854 4.646a.5.5 0 010 .708L5.207 8l2.647 2.646a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z" clipRule="evenodd" />
            <path fillRule="evenodd" d="M4.5 8a.5.5 0 01.5-.5h6.5a.5.5 0 010 1H5a.5.5 0 01-.5-.5z" clipRule="evenodd" />
          </svg>
        </button>

        <LoadingTitle title={title} loading={loading} />

        <button type="button" className="btn btn-primary d-flex align-items-center btn-sm" aria-expanded="false" dir="next" onClick={handlePag('next')}>
          <svg className="bi bi-arrow-right-short" width="28" height="28" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M8.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L10.793 8 8.146 5.354a.5.5 0 010-.708z" clipRule="evenodd" />
            <path fillRule="evenodd" d="M4 8a.5.5 0 01.5-.5H11a.5.5 0 010 1H4.5A.5.5 0 014 8z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      <GalleryGrid items={posts} />
    </div >
  );
};

type LoadingProps = {
  title: string,
  loading: string,
};

const LoadingTitle: React.FC<LoadingProps> = (props) => {
  return (
    <h1 className="page-title">
      {props.loading !== 'idle' ? <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div> : <span style={{width: '32px'}}></span>}

      <strong>{props.title}</strong>

      {props.loading !== 'idle' ? <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div> : <span style={{width: '32px'}}></span>}
    </h1>
  );
};

export default HomePage;
