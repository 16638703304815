import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import classNames from 'classnames';
import {useForm} from 'react-hook-form';
import './contact-page.scss';
import {
  postFeedback,
  selectFeedback,
  selectRunning,
  selectError,
} from './contactSlice';
import {FieldTypes} from '../../shared/formFieldsRegex';

const ContactPage: React.SFC = () => {
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const feedback = useSelector(selectFeedback);
  const running = useSelector(selectRunning);
  const resErrors = useSelector(selectError);
  const {register, handleSubmit, errors, formState, reset} = useForm({
    validateCriteriaMode: 'all',
    mode: 'onChange'
  });
  // https://react-hook-form.com/api#formState
  const {touched, isSubmitted} = formState;
  useEffect(() => {
    if (resErrors !== '') {
      setHasError(true);
      setShowAlert(true);
    }
  }, [resErrors]);
  useEffect(() => {
    if (feedback.data === 'Success') {
      setHasError(false);
      setShowAlert(true);
      setMessage('Tanks for reaching out, Message was send with success.');
      reset();
    }
  }, [feedback, reset]);
  const handleReset = () => (e: React.MouseEvent) => {
    reset();
  };
  const onSubmit = (data: any) => {
    dispatch(postFeedback({
      to: 'noreply@auxcoder.com',
      subject: 'Contact from auxcoder.com',
      message: JSON.stringify({
        name: data.name,
        email: data.email,
        message: data.message
      })
    }));
  };
  const closeAlert = () => {
  };
  const alertClasses = classNames({
    'alert alert-dismissible': true,
    'alert-success': true,
    'alert-danger': hasError,
    visible: showAlert,
    invisible: !showAlert,
  });
  return (
    <section className="container page-container" id="contactPage">
      <h1 className="page-title">Contact</h1>
      <p className="description">Here you can leave a message, we will reach you out as soon as posible. Thanks.</p>

      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} id="contactForm" noValidate>
            {showAlert && <div className={alertClasses} role="alert">
              {message}
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={closeAlert}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>}

            <fieldset className="mt-2">
              <div className="form-group">
                <label htmlFor="name">Name <span className="text-danger">*</span></label>
                <input
                  className={`form-control ${touched.name || isSubmitted ? errors.name?.types ? 'is-invalid' : '' : ''}`}
                  type="text"
                  name="name"
                  placeholder="enter name"
                  id="name"
                  autoComplete="name"
                  ref={register({required: 'Name is required', minLength: {value: 3, message: 'Name min length is 3 characters'}})}
                />

                {errors?.name && <ErrorMsgs errors={errors} field="name" />}
              </div>

              <div className="form-group">
                <label htmlFor="email">Email <span className="text-danger">*</span></label>
                <input
                  className={`form-control ${touched.email || isSubmitted ? errors.email ? 'is-invalid' : 'is-valid' : ''}`}
                  type="email"
                  name="email"
                  placeholder="enter email"
                  id="email"
                  autoComplete="email"
                  ref={register({required: 'Email is required', minLength: {value: 3, message: 'Email min length is 3 characters'}, pattern: {value: FieldTypes.email.re, message: FieldTypes.email.msg}})}
                />

                {errors?.email && <ErrorMsgs errors={errors} field="email" />}
              </div>

              <div className="form-group">
                <label htmlFor="message">Message <span className="text-danger">*</span></label>
                <textarea
                  className={`form-control ${touched.message || isSubmitted ? errors.message ? 'is-invalid' : 'is-valid' : ''}`}
                  name="message"
                  placeholder="enter message"
                  id="message"
                  rows={5}
                  ref={register({required: 'Message is required'})}
                />

                {errors?.message && <ErrorMsgs errors={errors} field="message" />}
              </div>

              <div className="form-group">
                <div className="control">
                  <button type="submit" value="submit" className={`btn btn-primary`} disabled={running !== 'idle'}>Enviar</button>
                  <button type="button" className={`btn btn-light`} onClick={handleReset()}>Reset</button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;

type ErrorProps = {
  errors: any;
  field: string;
};

const ErrorMsgs: React.SFC<ErrorProps> = (props) => {
  const errorMessages = (errors: any, fieldName: string) => {
    const types = errors[fieldName]?.types;
    return (Object.keys(types).map(rule => `${types[rule]}`).reduce((acc, curr) => `${acc} ${curr}`, ''));
  };
  return (
    <div className="message invalid-feedback">{errorMessages(props.errors, props.field)}</div>
  );
};
